@import"../variables";
.footer-area{
  background-color: $main;
  .foot-logo{
    height: 80px;
    .img-fluid.img-foot-logo{
      height: 80px;
    }
  }
  h4.foot-title {
    font-size: 26px;
    color: #fff;
    font-weight: 600;
    padding: 15px 0;
  }
  .foot-text{
    color: $white;
    p.text-body, a{
      color: $white!important;
    }
  }
  .con-social ul.nav li a{
    color: white;
  }
  .footer-botton{
    border-top: 1px solid #fff;
      span{
        color: #fff;
      }
  }
}
#back-to-top {
  position: fixed;
  right: 15px;
  bottom: 50px;
  z-index: 99999;
  .icon {
    opacity: 1;
    visibility: hidden;
    height: 50px;
    width: 50px;
    background-color: #cdcdcd75;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    color: #002d54;
    border-radius: 3px;
    cursor: pointer;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }
  &:hover{
    .icon{
      color: white;
    }
  }
  &.show{
    .icon{
      opacity: 1;
      visibility: visible;
      height: 40px;
      width: 40px;
    }
  }
}