@import "../variables";

.m-list-lang {
  display: none;
  position: fixed;
  left: 15px;
  bottom: 50px;
  z-index: 9999;
}

.top-bar {
  background-color: $main;

  ul.nav {
    align-items: center;
    height: 42px;

    li, a {
      color: white;
      padding-right: 15px;

      i {
        padding-right: 10px;
      }

      a.nav-link {
        color: white;
        padding: 5px 9px;
      }

      &.lang {
        padding-right: 0;

        img {
          height: 28px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;

        }
      }
    }

  }
}

#menu .col-3.con-logo .image {

}

#menu {
  transition: all .5s;
  border-bottom: 2px solid $gold-main;

  &.fixed {
    .container {
      position: fixed;
      width: 100%;
      top: 0;
      background-color: #fff;
      z-index: 99;
      padding: 0 5%;
      border-bottom: 2px solid $gold-main;

      .row {
        width: 100%;
        margin: 0;
      }
    }
  }

  .col-3.con-logo {
    .image {
      //display: none;
      display: flex;
      align-items: center;
      height: 90px;
    }

    flex: 1;
    max-width: 320px;
    height: 80px;

    .img-fluid.img-logo {
      max-width: 100%;
      padding: 5px 0;

    }
  }

  .col-9.center-menu {
    flex: 1;
    max-width: calc(100% - 330px);

    ul.nav {
      align-items: center;
      height: 80px;

      li {
        position: relative;

        &.active {
          a.nav-link {
            color: $gold-main;
          }
        }

        a.nav-link {
          transition: all .1s;
          font-family: "text-en";
          padding: 5px 9px;
          font-weight: 600;
          font-size: 14px;
          text-transform: uppercase;
        }

      }
    }
  }

  .col-9.right-menu {
    display: none;
    flex: 1;
    max-width: calc(100% - 300px);

    ul.nav {
      align-items: center;
      height: 100px;

      li.lang {
        display: none;
      }

      #nav-phone i {
        padding: 12px;
        font-size: 18px;
        color: $gold-main;
        background-color: $main;
      }
    }
  }

  &.show {
    #mobile-menu {
      display: block;
      left: 15px;
      opacity: 1;
      visibility: visible;
    }
  }

  #mobile-menu {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    left: -15%;
    top: 0;
    height: 100vh;
    width: 100%;
    background-color: #f2f2f2d1;
    z-index: 9999;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;

    .list-menu {
      position: relative;
      padding-left: 10%;
      padding-top: 10%;
      width: 70%;
      background: #ffffff;
      height: 100vh;
      overflow-y: scroll;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5;
      }

      &::-webkit-scrollbar {
        width: 0;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }

      ul.nav {
        li.nav-item {
          position: relative;

          a.nav-link {
            font-size: 18px;
          }

          &.lang {
            a {
              width: 30px;
              height: 30px;
              overflow: hidden;
              display: flex;
              border-radius: 50%;
              align-items: center;
              margin-left: 15px;

              img.img-fluid {
                height: 30px;
              }

            }

          }
        }
      }


    }

    .icon-close {
      &.left, &.top {
        position: absolute;
        z-index: 99999;
        padding: 10px;
        cursor: pointer;
        font-size: 18px;
        height: 45px;
        width: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.left {
        left: 3%;
        top: 20%;
        background-color: #efefef;
        padding: 10px;
        cursor: pointer;
        font-size: 14px;
        height: 100px;
        width: 45px;
      }

      &.top {
        right: 10px;
        top: 10px;
        background-color: #efefef;
      }
    }
  }

}

@media (max-width: 1024px) {
  #menu {
    .col-9.right-menu {
      display: block;
      ul.nav {
        height: 70px;
      }
    }
   .col-3.con-logo {
     max-width: 300px;
     height: 70px;
     .image{
       height: 70px;
     }
   }
  }

  #menu .col-9.center-menu {
    display: none;
  }
}
.modal{
  top: 5%!important;
  .modal-dialog.display{
    width: 80%;
  }
}
@media (max-width: 992px) {
  #menu.show.fixed .container, #menu.fixed .container {
    padding: 0 5% !important;
  }
}

@media (max-width: 768px) {
  #menu {
    .col-9.right-menu {
      ul.nav {
        height: 70px;
      }
    }
    .col-3.con-logo {
      max-width: 220px;
      height: 70px;
      .image{
        height: 70px;
      }
    }
  }
  .m-list-lang {
    display: block;

    li.lang {
      display: block;
      margin-right: 15px;

      a.nav-link {
        padding: 0;

        img {
          height: 28px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;

        }
      }
    }

  }
  .top-bar {
    .list-lang {
      display: none;
    }

    .info {
      text-align: center;
    }
    ul.nav li {
      color: white;
      padding-right: 15px;
      line-height: 15px;
    }
  }
  #menu {
    .col-3.con-logo {
      height: 70px;

      .img-fluid.img-logo {
        padding: 5px 0;
      }
    }

    .col-9.right-menu {
      max-width: calc(100% - 220px);
      ul.nav {
        height: 70px;


      }

    }
  }
}

@media (max-width: 568px){
  #menu.show.fixed .container, #menu.fixed .container {
    padding: 0 2.5% !important;
  }
}
@media (max-width: 480px) {
  #menu #mobile-menu {
    .icon-close.left {
      left: 0;
      top: 25%;
      height: 70px;
      font-size: 10px;
      width: 40px;
    }
  }
}