//font
@font-face {
  font-family: 'title-en';
  src: url('../fonts/LemonMilk.woff2') format('woff2'), url('../fonts/LemonMilk.woff') format('woff'), url('../fonts/LemonMilk.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}



@font-face {
  font-family: 'text-en';
  src: url('../fonts/times-new-roman.woff2') format('woff2'), url('../fonts/times-new-roman.woff') format('woff'), url('../fonts/times-new-roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'title-kh';
  src: url('../fonts/Hanuman.woff2') format('woff2'), url('../fonts/Hanuman.woff') format('woff'), url('../fonts/Hanuman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}



@font-face {
  font-family: 'text-kh';
  src: url('../fonts/Hanuman.woff2') format('woff2'), url('../fonts/Hanuman.woff') format('woff'), url('../fonts/Hanuman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif:  sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$text-body: #212529;
$main: #153158;
$gold-main: #cf9455;
$gray-main: #f5f5f5;
$orange-main: #c7953c;
$green-main: #51be95;
$violet-main: #512e89;
$white: #ffffff;
$text-bold: #000000;
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
